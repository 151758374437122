import { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addNewURISchema } from "../../../helpers/validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addPage,
  deletePage,
  showPages,
  updatePage,
} from "../services/pages.services";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { NormalConfirmation } from "../../../components/Modal/SimpleConfirmation";

import { setURI } from "../../../reducers/uris.reducer";
import ShowPageModal from "./ShowPageModal";
import { DataInterface, PageInterface } from "../types/websiteInsitghts.types";
import AddPageModal from "./AddPageModal";
import { showToast } from "../../../helpers/notification";

export const ManagePageModal = () => {
  const INITIAL_DATA = {
    id: 0,
    uri: "",
    uuid: "",
  };

  const dispatch = useDispatch();
  const projectData = useSelector((state: RootState) => state.project);
  const pageData = useSelector((state: RootState) => state.page);
  const [data, setData] = useState<DataInterface>({ ...INITIAL_DATA });
  const [allPages, setAllPages] = useState<PageInterface[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddPageModal, setShowAddPageModal] = useState(false);
  const [editMode, setEditMode] = useState(0);
  const [showConfirmModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const INITAL_DATA_CONFIRMATION = {
    id: 0,
    name: "",
  };
  const [dataForConfirmation, setDataForConfiramtion] = useState(
    INITAL_DATA_CONFIRMATION
  );
  const values = data;

  const getAllPages = async () => {
    setLoading(true);
    const showAllPages = await showPages({ project_id: projectData.projectId });
    if (showAllPages?.status === 200) {
      setAllPages(showAllPages.data.data);
    }
    setLoading(false);
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm<DataInterface | any>({
    values,
    resolver: yupResolver(addNewURISchema),
  });

  const onSubmit = async (uriData: DataInterface) => {
    if (editMode) {
      setButtonLoader(true);
      const updatePageURI = await updatePage({
        ...uriData,
        project_id: projectData.projectId,
        id: data.id,
        test_accessibility_total_time_avg: 2,
      });
      if (updatePageURI?.status === 200) {
        showToast(updatePageURI.data.message, "success");

        getAllPages();
        setEditMode(0);
        setShowAddPageModal(false);
        setData(INITIAL_DATA);

        if (pageData.page_id === uriData.id) {
          dispatch(
            setURI({
              id: uriData.id,
              uuid: uriData.uuid,
              uri: uriData.uri,
              project_id: projectData.projectId,
            })
          );
        }
      }
      setButtonLoader(false);
      return;
    } else {
      setButtonLoader(true);
      try {
        const addPageURI = await addPage({
          uri: uriData.uri,
          project_id: projectData.projectId,
          test_accessibility_total_time_avg: 2,
        });

        if (addPageURI.status === 200) {
          getAllPages();
          setShowAddPageModal(false);
        } else if (addPageURI.status === 400) {
          setError("uri", {
            type: "manual",
            message: addPageURI.data.message,
          });
        }
        setButtonLoader(false);
      } catch (e: any) {
        setButtonLoader(false);
        setError("uri", { type: "manual", message: e.response.data.message });
      }
    }
  };

  const onOkConfirm = async (id: number) => {
    setButtonLoader(true);
    const response = await deletePage(id);
    showToast(response?.data.message, "error");
    if (response?.status === 200) {
      const showAllPages = await showPages({
        project_id: projectData.projectId,
      });
      const { id, uri, project_id, uuid } = showAllPages?.data.data[0];
      const data = {
        id,
        uuid,
        project_id,
        uri,
      };
      dispatch(setURI(data));
      getAllPages();
      setShowConfirmationModal(false);
    }
  };

  const handleDelete = async (
    e: MouseEvent<HTMLButtonElement>,
    data: PageInterface
  ) => {
    e.stopPropagation();
    setDataForConfiramtion({ id: parseInt(data.id), name: data.uri });
    setShowConfirmationModal(true);
  };
  const handlePageChange = (data: PageInterface) => {
    dispatch(setURI(data));
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal === false) {
      const rootElement = document.getElementById("root") as HTMLElement;
      rootElement.style.overflow = "visible";
    }
  }, [showModal]);

  return (
    <>
      <button
        type="button"
        className="w-full bg-white text-12px leading-4 font-bold text-black rounded-lg px-4 py-3"
        onClick={() => {
          getAllPages();
          setShowModal(true);
        }}
      >
        Pages
      </button>

      {showModal && (
        <ShowPageModal
          showModal={showModal}
          setShowModal={setShowModal}
          loading={loading}
          allPages={allPages}
          handlePageChange={handlePageChange}
          setData={setData}
          setEditMode={setEditMode}
          setShowAddPageModal={setShowAddPageModal}
          handleDelete={handleDelete}
          INITIAL_DATA={INITIAL_DATA}
          editMode={editMode}
        />
      )}

      {showAddPageModal && (
        <AddPageModal
          showAddPageModal={showAddPageModal}
          setShowAddPageModal={setShowAddPageModal}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          projectData={projectData}
          register={register}
          errors={errors as {}}
          buttonLoader={buttonLoader}
          reset={reset}
        />
      )}
      {showConfirmModal && (
        <NormalConfirmation
          element={dataForConfirmation}
          showConfirmationModal={showConfirmModal}
          setShowConfirmationModal={setShowConfirmationModal}
          onOk={onOkConfirm}
          message=" We'll delete all the related information related this URI, and
          once deleted it is not possible to recover it. Delete only if
          you're sure!"
        />
      )}
    </>
  );
};
