import { Modal } from "../../../components/Modal/Modal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { getPlanPaymentDetails } from "../services/account.service";
import { Loading } from "../../../components/Loader/Loading";
import { PlanPaymantStats } from "../types/project.types";
const PlanPaymentModel = ({ showModal, setShowModal }: any) => {
  const { account_id } = useSelector((state: RootState) => ({
    account_id: state.account.id,
  }));
  const [daysLeft, setDaysLeft] = useState(0);
  const INITIAL_STATE = {
    accountPlan: {
      account_id: 0,
      created_at: 0,
      credits_available: 0,
      credits_used: 0,
      id: 0,
      is_paid: 0,
      plan_id: 0,
      updated_at: 0,
      uuid: "",
    },
    planDetails: {
      description: "",
      id: 0,
      is_public: 0,
      name: "",
      period: 0,
      price: 0,
      uuid: "",
    },
    planResources: {
      accessibility_test_frequency: 0,
      alert_people_amount: 0,
      alert_rules_amount: 0,
      antivirus_pages_amount: 0,
      can_create_test_accessibility: 0,
      can_create_test_efficiency: 0,
      can_create_test_event: 0,
      can_create_test_functionality: 0,
      can_create_test_recommendation: 0,
      crawled_depth_amount: 0,
      crawled_pages_amount: 0,
      credits_amount: 0,
      datacenters_amount: 0,
      id: 0,
      plan_id: 0,
      projects_amount: 0,
      uris_amount: 0,
      uuid: ",",
    },
    projectCount: 0,
  };
  const [isLoading, setLoading] = useState(true);
  const [planPaymentData, setPlanPaymentData] =
    useState<PlanPaymantStats>(INITIAL_STATE);

  const getPlansPayment = async () => {
    try {
      setLoading(true);
      const data = { account_id: account_id };
      const response = await getPlanPaymentDetails(data);
      setPlanPaymentData(response?.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  useEffect(() => {
    const timestamp = Number(
      new Date(planPaymentData?.accountPlan?.created_at * 1000)
    );
    const currentDate = new Date().getTime();

    const timeDifference = timestamp - currentDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference < 0) {
      setDaysLeft(0);
      // localStorage.clear();
      // navigate('/login')
    } else {
      setDaysLeft(daysDifference);
    }

    if (showModal) {
      getPlansPayment();
    }
  }, []);
  return (
    <>
      <Modal
        setShowModal={setShowModal}
        title="Plan & Payment"
        showModal={showModal}
        shouldBgScroll={false}
        fullScreen={true}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="col-12 h-[700px] overflow-y-auto p-6 md:p-8 flex-auto font-normal text-14px leading-4 text-black dark:text-slate-50">
              {planPaymentData.planDetails && planPaymentData.planDetails.name}

              <div className="bg-primaryblue px-2 py-2">
                <b>Credits</b>
              </div>

              <div className="mt-4">
                <b>Credits available</b>

                <div className="text-right">
                  {planPaymentData.accountPlan &&
                    planPaymentData.accountPlan.credits_available}
                </div>
              </div>

              <div className="mt-4">
                <b>Credits used</b>

                <div className="text-right">
                  {" "}
                  {planPaymentData.accountPlan &&
                    planPaymentData.accountPlan.credits_used}
                </div>
              </div>

              <div className="mt-4">
                <div>
                  <b>Credits left</b>
                </div>
                <div className="text-right">
                  {" "}
                  {planPaymentData.accountPlan &&
                    planPaymentData.accountPlan.credits_available -
                      planPaymentData.accountPlan.credits_used}
                </div>
              </div>

              <div className="bg-primaryblue px-2 py-2">
                <b>Resources</b>
              </div>
              <div className="mt-4">
                <ul className="list-none">
                  <li className="border-b border-gray-300 py-2">
                    <b>Projects/URLs</b>{" "}
                    <div className="float-right">
                      {planPaymentData.projectCount} /{" "}
                      {planPaymentData.planResources.projects_amount}
                    </div>
                  </li>
                  <li className="border-b border-gray-300 py-2">
                    <b>Urls tested</b>{" "}
                    <div className="float-right">
                      {planPaymentData.planResources.uris_amount}
                    </div>
                  </li>
                  <li className="border-b border-gray-300 py-2">
                    <b>Uptime Monitor Frequency</b>{" "}
                    <div className="float-right">
                      each{" "}
                      {
                        planPaymentData.planResources
                          .accessibility_test_frequency
                      }{" "}
                      min.
                    </div>
                  </li>
                  <li className="border-b border-gray-300 py-2">
                    <b>Antivirus scanned pages</b>{" "}
                    <div className="float-right">
                      {" "}
                      {planPaymentData.planResources.antivirus_pages_amount}
                    </div>
                  </li>
                  <li className="border-b border-gray-300 py-2">
                    <b>Crawled pages</b>{" "}
                    <div className="float-right">
                      {planPaymentData.planResources.crawled_pages_amount}
                    </div>
                  </li>
                  <li className="border-b border-gray-300 py-2">
                    <b>Crawler depth</b>{" "}
                    <div className="float-right">
                      {planPaymentData.planResources.crawled_depth_amount}
                    </div>
                  </li>
                  <li className="border-b border-gray-300 py-2">
                    <b>Alerting rules</b>{" "}
                    <div className="float-right">
                      {planPaymentData.planResources.alert_rules_amount}
                    </div>
                  </li>
                  <li className="py-2">
                    <b>Additional users for alerts</b>{" "}
                    <div className="float-right">
                      {planPaymentData.planResources.alert_people_amount}
                    </div>
                  </li>
                </ul>
              </div>

              <div className="bg-primaryblue px-2 py-2">
                <div>
                  <b>Payments</b>
                </div>
              </div>

              <div className="bg-gray-300 flex justify-between px-2 py-2">
                <div>Started At</div>
                <div>Plan</div>
                <div>Days remaining</div>
              </div>

              <div className="mt-4 flex justify-between">
                <div>
                  {new Date(
                    planPaymentData?.accountPlan?.created_at * 1000
                  ).toLocaleString()}
                </div>
                <div>{daysLeft} Days Left</div>
                <div>
                  <span className="mr-2">
                    ${planPaymentData.planDetails.price}
                  </span>
                  {planPaymentData.planDetails.name}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default PlanPaymentModel;
