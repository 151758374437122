import GlobalLoader from "../components/Loader/GlobalLoader";
import RequiredAuth from "../components/AuthGuard/RequiredAuth";
import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import { PageNotFound } from "../components/PageNotFound/PageNotFound";
import { RouteInterface } from "../types/generalTypes";

export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader />}>
        <Routes>
          {routes.map((route: RouteInterface) => (
            <Route
              index={route?.index ? true : false}
              key={route.path}
              path={route.path}
              element={<RequiredAuth {...route} />}
            ></Route>
          ))}
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
