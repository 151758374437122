import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import { useEffect, useState } from "react";
import { handleGetAllProjects } from "../../reducers/project.reducer";
import GlobalLoader from "../Loader/GlobalLoader";

export const PageNotFound = () => {
  let hasProject: string = useSelector(
    (state: RootState) => state.project.projectUUID
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [goToHome, setGoToHome] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(handleGetAllProjects());
    setLoading(false);
  }, []);
  useEffect(() => {
    setLoading(true);
    if (hasProject !== "" || goToHome) {
      setLoading(false);
      navigate(`/project/${hasProject}`);
    } else {
      return;
    }
  }, [hasProject]);

  if (loading) {
    return <GlobalLoader />;
  }
  return (
    <div
      className="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    bg-gradient-to-r
    from-indigo-600
    to-blue-400
  "
    >
      <div className="px-40 py-20 bg-white rounded-md shadow-xl">
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-blue-600 text-9xl">404</h1>

          <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
            <span className="text-red-500">Oops!</span> Page not found
          </h6>

          <p className="mb-8 text-center text-gray-500 md:text-lg">
            The page you’re looking for doesn’t exist.
          </p>

          <Link
            to={`project/${hasProject}`}
            onClick={() => setGoToHome(true)}
            className="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
          >
            Go home
          </Link>
        </div>
      </div>
    </div>
  );
};
