import { useSelector } from "react-redux";
import Sitelogo from "../../assets/pagemtr-logo.svg";
import SitelogoWhite from "../../assets/pagemtr-white.png";
import { RootState } from "../../store";
const PrivateFooter = () => {
  const theme = useSelector((state: RootState) => state.theme.theme);
  return (
    <div className="p-4 md:px-7 md:py-7 dark:bg-slate-600">
      <footer className="site-footer rounded-xl px-8 py-8 md:px-14 md:py-10 md:pb-0 bg-primarylightblue pb-0 dark:bg-slate-700">
        <div className="flex text-left pb-6 md:pb-10 flex-row">
          <div className="w-full">
            {theme === "dark" ? (
              <img
                src={SitelogoWhite}
                alt="Sitelogo"
                className="m-auto w-[180px]"
              />
            ) : (
              <img src={Sitelogo} className="m-auto" alt="Sitelogo" />
            )}
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap text-left border-b border-black/20 dark:border-slate-50/60 pb-7 md:pb-10 flex-row">
          <div className="w-full md:w-1/2 pr-0 md:pr-40 mb-4 md:mb-0">
            <h4 className="font-semibold text-20px leading-7 text-black dark:text-slate-50">
              About PageMTR
            </h4>
            <p className="font-normal text-14px leading-5 text-black mt-3 md:mt-4 dark:text-slate-50/70">
              PageMTR is a tool for webmasters, developers, website managers,
              and website owners. The tool allows users to easily identify
              performance issues and security vulnerabilities on any website,
              resulting in a faster and more secure experience for your clients.
            </p>
          </div>
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <h4 className="font-semibold text-20px leading-7 text-black dark:text-slate-50">
              Features
            </h4>
            <ul className="font-normal text-14px leading-5 text-black mt-3 md:mt-4 dark:text-slate-50/70">
              <li className="mb-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://pagemtr.com/uptime-monitoring"
                  className="underline"
                >
                  Free Uptime Monitoring Service
                </a>
              </li>
              <li className="mb-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://pagemtr.com/core-web-vitals-monitoring"
                  className="underline"
                >
                  Core Web Vitals Monitoring
                </a>
              </li>
              <li className="mb-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://pagemtr.com/website-safety-monitoring"
                  className="underline"
                >
                  Website Safety Monitoring
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://pagemtr.com/events-monitoring"
                  className="underline"
                >
                  Event Monitoring
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2">
            <h4 className="font-semibold text-20px leading-7 text-black dark:text-slate-50">
              Contact
            </h4>
            <p className="font-normal text-14px leading-5  text-black mt-3 md:mt-4 dark:text-slate-50/70">
              PAGEMTR.COM 321 S.
              <br /> Boston Suite 300 Tulsa,
              <br /> OK 74103
            </p>
          </div>
        </div>
        <div className="flex text-left pt-6 pb-6 md:pt-8 md:pb-8 flex-row">
          <div className="w-full">
            <p className="font-bold text-16px leading-6  md:text-20px md:leading-7 text-center dark:text-slate-50/70">
              Customer Support:{" "}
              <a
                target="_blank"
                href="mailto:admin@pagemtr.com"
                className="font-semibold"
                rel="noreferrer"
              >
                admin@pagemtr.com
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivateFooter;
