import GlobalLoader from "../Loader/GlobalLoader";
import PrivateLayout from "../../layout/privatelayout/PrivateLayout";
import PublicLayout from "../../layout/publiclayout/PublicLayout";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { AppDispatch, RootState } from "../../store";
import { useSelector } from "react-redux";
import { handleGetAllProjects } from "../../reducers/project.reducer";
import { useDispatch } from "react-redux";
import { setAccount } from "../../reducers/account.reducer";
import { setURI } from "../../reducers/uris.reducer";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getYourAccount } from "../../pages/websiteInsights/services/account.service";
import { showPages } from "../../pages/websiteInsights/services/pages.services";
import { RouteInterface } from "../../types/generalTypes";

const RequiredAuth = (props: RouteInterface) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { token: hasToken, projectUUID: hasProject } = useSelector(
    (state: RootState) => ({
      token: state.token.token,
      projectUUID: state.project.projectUUID,
    })
  );

  const location = useLocation();
  const { uuid } = useParams();
  const getData = async () => {
    const { data } = await getYourAccount();
    dispatch(setAccount({ id: data.id, uuid: data.uuid }));
  };
  const updatePages = async (id: number) => {
    const getLastPage = await showPages({
      project_id: id,
    });
    const { data } = getLastPage?.data;

    dispatch(
      setURI({
        id: data[0]?.id,
        uuid: data[0]?.uuid,
        uri: data[0]?.uri,
        project_id: id,
      })
    );
  };

  const handleInitialApiCalls = async () => {
    const data = await dispatch(handleGetAllProjects(uuid));

    updatePages(data?.payload?.id);
    getData();
  };
  useEffect(() => {
    if (hasToken) {
      handleInitialApiCalls();
    }
    // eslint-disable-next-line
  }, [hasToken]);

  useEffect(() => {
    if (hasToken && props.isPublic && hasProject !== "") {
      navigate(`/project/${hasProject}`);
    } else {
      if (uuid !== hasProject && uuid && hasProject !== "") {
        navigate(`/project/${hasProject}`);
      }
      if (!hasToken) {
        if (location.pathname.startsWith(`/forgot-password`)) {
          navigate(location.pathname);
        } else {
          navigate("/login");
        }
      }
      return;
    }
    // eslint-disable-next-line
  }, [hasProject, hasToken]);

  if (hasProject && hasToken && props.isPublic && hasProject) {
    navigate(`/project/${hasProject}`);
  }

  if (props.isPublic && !hasToken && !localStorage.getItem("authToken")) {
    return (
      <>
        <PublicLayout>
          <props.element />
        </PublicLayout>
      </>
    );
  } else if (props.isPrivate && hasToken) {
    return (
      <PrivateLayout>
        <props.element />
      </PrivateLayout>
    );
  } else {
    return <GlobalLoader />;
  }
};

export default RequiredAuth;
