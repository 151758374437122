import { AxiosError } from "axios";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../../../axios/method";
import {
  CreateAnnotationInterface,
  UpdateAnnotationInterface,
} from "../types/websiteInsitghts.types";

export const getAnnotation = async (uuid: string) => {
  try {
    let response = await axiosGet(`/get-annotation/${uuid}`);
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};

export const createAnnotation = async (data: CreateAnnotationInterface) => {
  try {
    let response = await axiosPost("/annotation", { ...data });
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};

export const updateAnnotation = async (data: UpdateAnnotationInterface) => {
  try {
    let response = await axiosPut("/update-annotation", { ...data });
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};

export const deleteAnnotation = async (id: number) => {
  try {
    let response = await axiosDelete(`/delete-annotation/${id}`);
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};
