import Sitelogo from "../assets/pagemtr-logo.svg";
import SitelogoWhite from "../assets/pagemtr-white.svg";
import PageUrl from "../assets/link.svg";
import WebUrl from "../assets/weblink.svg";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../reducers/token.reducers";
import { WorklogModal } from "../pages/websiteInsights/components/WorklogModal";
import { AppDispatch, RootState } from "../store";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useEffect, useRef, useState } from "react";
import { ManagePageModal } from "../pages/websiteInsights/components/ManagePageModal";
import { resetProject } from "../reducers/project.reducer";
import { resetURI } from "../reducers/uris.reducer";
import { sidebarDetails } from "../utils/_const";
import { SidebarType } from "../types/generalTypes";
import { setSidebarOpen } from "../reducers/sidebar.reducer";
import { useOutsideAlterer } from "../hooks/useOutsideAlterer";
import { LogoutConfirmation } from "../pages/authentication/components/LogoutConfirmation";

const Sidebar = () => {
  const projectData = useSelector((state: RootState) => state.project);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const [navigateWithUUID, setNavigateWithUUID] = useState("");
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.open);
  const wrapperRef = useRef(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  useOutsideAlterer("sidebar", wrapperRef);
  const handleLogout = () => {
    dispatch(setToken(""));
    dispatch(resetProject());
    dispatch(resetURI());
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  const { hasProject, currentPageUri, theme } = useSelector(
    (state: RootState) => ({
      hasProject: state.project.projectUUID,
      currentPageUri: state.page.uri,
      theme: state.theme.theme,
    })
  );

  const isActiveURI = (path: string) => path === pathname;

  useEffect(() => {
    if (hasProject && hasProject !== "") {
      setNavigateWithUUID(hasProject);
    }
  }, [hasProject]);

  return (
    <>
      <aside id="default-sidebar" aria-label="Sidebar">
        <div
          ref={wrapperRef}
          className={`fixed top-0 ${
            isSidebarOpen && "left-72"
          }  md:left-0 z-40 w-72 transition-transform -translate-x-full sm:translate-x-0 h-full overflow-y-auto bg-white dark:bg-slateDark overflow-hidden`}
        >
          <Link
            className=" px-7 py-8 pb-0 block"
            to={`/project/${navigateWithUUID}`}
          >
            {theme === "light" ? (
              <img src={Sitelogo} alt="Sitelogo" className="w-[190px]" />
            ) : (
              <img src={SitelogoWhite} alt="Sitelogo" className="w-[190px]" />
            )}
          </Link>

          <ul className="space-y-2 font-bold text-primarydark/50 pl-5 mt-8 md:mt-14 ">
            {sidebarDetails.map((sidebar: SidebarType, index: number) => {
              return (
                <li key={index}>
                  <Link
                    to={`/project/${navigateWithUUID + sidebar.path}`}
                    className={`relative flex gap-2 items-center ${
                      isActiveURI(`/project/${navigateWithUUID + sidebar.path}`)
                        ? "before:opacity-100 text-primaryblue"
                        : "before:hover:opacity-100 before:opacity-0"
                    } before:absolute before:content-[''] before:w-8 before:h-14 before:rounded-2xl before:bg-primaryblue before:-top-0 before:-right-4 flex items-center p-2 py-4 dark:text-slate-400 dark:hover:text-white hover:text-primaryblue text-16px leading-5 group/hss`}
                  >
                    <i
                      className={`${
                        sidebar.icon
                      } text-20px hover:dark:text-slate-50 ${
                        isActiveURI(
                          `/project/${navigateWithUUID + sidebar.path}`
                        )
                          ? "text-primaryblue"
                          : ""
                      }`}
                    ></i>
                    <span
                      onClick={() => dispatch(setSidebarOpen(false))}
                      className={`ml-4 justify-start hover:dark:text-slate-50 ${
                        isActiveURI(
                          `/project/${navigateWithUUID + sidebar.path}`
                        )
                          ? "text-primaryblue"
                          : ""
                      } `}
                    >
                      {sidebar.name}
                    </span>
                  </Link>
                </li>
              );
            })}

            <li className="pr-5">
              <div className="static-resource bg-primaryblue p-4 rounded-lg">
                <Link
                  to={`https://${projectData.projectName}`}
                  // to="https://www.example.com"
                  target="_blank"
                  id="url-title"
                  className="flex items-center justify-start text-16px leading-5 text-white font-bold mb-3"
                >
                  <img src={WebUrl} alt="WebUrl" id="url-title" />
                  <span className="ml-3">
                    {projectData &&
                    projectData?.projectName &&
                    projectData.projectName.split("").length > 16
                      ? projectData.projectName.slice(0, 16) + "..."
                      : projectData.projectName}
                  </span>
                </Link>
                <ReactTooltip
                  style={{ maxWidth: "100%" }}
                  anchorSelect="#url-title"
                  place="bottom"
                  content={`${projectData.projectName}`}
                />
                <span className="flex items-center justify-start text-16px leading-5 text-white font-bold mb-3">
                  <img src={PageUrl} alt="PageUrl" id="currentpageUri-title" />
                  <span className="ml-3" id="currentpageUri-title">
                    {currentPageUri.split("").length > 10
                      ? currentPageUri.slice(0, 16) + "..."
                      : currentPageUri}
                  </span>
                </span>
                <ReactTooltip
                  style={{ maxWidth: "100%" }}
                  anchorSelect="#currentpageUri-title"
                  place="bottom"
                  content={`${currentPageUri}`}
                />
                <div className="flex flex-row gap-3 mt-4" id="worklog">
                  {projectData.projectId !== 0 && (
                    <>
                      <WorklogModal />
                      <ManagePageModal />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <span
                onClick={() => setShowConfirmationModal(true)}
                className="relative cursor-pointer before:hover:opacity-100 before:opacity-0 before:absolute before:content-[''] before:w-8 before:h-14 before:rounded-2xl before:bg-primaryblue before:-top-0 before:-right-4 flex items-center p-2 py-4   hover:text-primaryblue text-16px leading-5 group/hs"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  className="fill-primarygrey-dark group-hover/hs:fill-primaryblue"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.63066 1.18945C3.17434 1.18945 1.18311 3.18069 1.18311 5.63701V11.1965H12.9533L10.4039 8.64702C9.9697 8.21279 9.9697 7.50879 10.4039 7.07456C10.8381 6.64035 11.5421 6.64035 11.9763 7.07456L16.4231 11.5214L16.4311 11.5295C16.5342 11.6343 16.6121 11.7546 16.6652 11.8827C16.7192 12.0129 16.7491 12.1555 16.7496 12.305V12.3083V12.3117C16.7487 12.6137 16.6274 12.8874 16.4311 13.0872L16.4231 13.0953L11.9763 17.5421C11.5421 17.9763 10.8381 17.9763 10.4039 17.5421C9.9697 17.1079 9.9697 16.4039 10.4039 15.9697L12.9533 13.4202H1.18311V18.9797C1.18311 21.436 3.17434 23.4272 5.63066 23.4272H18.9733C21.4296 23.4272 23.4209 21.436 23.4209 18.9797V5.63701C23.4209 3.18069 21.4296 1.18945 18.9733 1.18945H5.63066Z" />
                </svg>
                <span className="ml-4 dark:text-slate-400">Logout</span>
              </span>
            </li>
          </ul>
        </div>
      </aside>
      <LogoutConfirmation
        setShowConfirmationModal={setShowConfirmationModal}
        showConfirmationModal={showConfirmationModal}
        handleLogout={handleLogout}
      />
    </>
  );
};
export default Sidebar;
