import { Loading } from "../../../components/Loader/Loading";
import { Modal } from "../../../components/Modal/Modal";
import { DataInterface, PageInterface } from "../types/websiteInsitghts.types";
import EditIcon from "../../../assets/edit-icon.svg";
import DeleteIcon from "../../../assets/trash.svg";
import { Dispatch, SetStateAction } from "react";

const ShowPageModal = ({
  showModal,
  setShowModal,
  loading,
  allPages,
  handlePageChange,
  setData,
  setEditMode,
  setShowAddPageModal,
  handleDelete,
  INITIAL_DATA,
  editMode,
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  allPages: PageInterface[];
  handlePageChange: Function;
  setData: Dispatch<SetStateAction<DataInterface>>;
  setEditMode: Dispatch<SetStateAction<number>>;
  setShowAddPageModal: Dispatch<SetStateAction<boolean>>;
  handleDelete: Function;
  INITIAL_DATA: {
    id: number;
    uri: string;
    uuid: string;
  };
  editMode: number;
}) => {
  return (
    <>
      {" "}
      <Modal
        title="Manage Pages"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <div className="relative p-6 md:p-8 flex-auto m-2 h-[500px] overflow-y-auto">
          <div className="search-results">
            {loading ? (
              <Loading />
            ) : (
              <>
                <ul className="mt-1 mb-5">
                  {allPages.map((data: PageInterface, index: number) => {
                    return (
                      <li
                        onClick={() => handlePageChange(data)}
                        key={index}
                        className="flex items-center cursor-pointer justify-between border-b dark:text-slate-50 dark:border-slate-500 border-b-black/10 py-3 text-primarydakblack font-medium text-16px leading-6"
                      >
                        <p>{data.uri}</p>
                        <div className="flex gap-2">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setData({
                                uri: data.uri,
                                id: parseInt(data.id),
                                uuid: data.uuid,
                              });
                              setEditMode(1);
                              setShowAddPageModal(true);
                            }}
                          >
                            <img src={EditIcon} alt="editicon" />
                          </button>
                          <button
                            onClick={(e) => {
                              handleDelete(e, data);
                            }}
                          >
                            <img src={DeleteIcon} alt="editicon" />
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <button
                  className="bg-primaryblue text-white border border-primaryblue hover:bg-white hover:text-primaryblue font-poppins text-14px leading-5 font-bold uppercase text-sm px-6 py-3 rounded-xl"
                  type="submit"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (editMode) {
                      setEditMode(0);
                    }
                    setData(INITIAL_DATA);
                    setShowAddPageModal(true);
                  }}
                >
                  Add New Page
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ShowPageModal;
