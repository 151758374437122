import ProjectModal from "../../pages/websiteInsights/components/ProjectModal";
import { useDispatch } from "react-redux";
import { setTheme } from "../../reducers/theme.reducer";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import LightIcon from "../../assets/light.svg";
import DarkIcon from "../../assets/night-mode.svg";
import { setSidebarOpen } from "../../reducers/sidebar.reducer";
import PlanPayment from "../../pages/websiteInsights/components/PlanPayment";
const PrivateHeader = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector((state: RootState) => state.theme.theme);

  const handleTheme = () => {
    if (currentTheme === "light") {
      dispatch(setTheme("dark"));
    } else {
      dispatch(setTheme("light"));
    }
  };

  const openSidebar = () => {
    dispatch(setSidebarOpen(true));
  };

  return (
    <nav className="w-full bg-white dark:bg-slate-700 dark:border-slate-600">
      <div className="py-4 px-3 md:px-7 md:py-7">
        <div className="flex items-center">
          <div className="w-full md:w-2/4 flex items-center justify-start">
            <button
              data-drawer-target="default-sidebar"
              data-drawer-toggle="default-sidebar"
              aria-controls="default-sidebar"
              type="button"
              className="text-sm mr-2 text-gray-500 sm:hidden dark:text-white"
              onClick={openSidebar}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>
            {/* <a
              href="/"
              className="group/hs relative text-white flex items-center border-solid border-2 border-primaryblue border-bg-primaryblue hover:bg-white hover:text-primaryblue bg-primaryblue   px-1 py-1.5 md:px-7 md:py-3 rounded-lg md:rounded-xl group/hs"
            >
              <svg
                className=" w-3 h-3 md:w-3.5 md:h-3.5 fill-white group-hover/hs:fill-primaryblue"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 7C0 6.44772 0.447715 6 1 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H1C0.447715 8 0 7.55228 0 7Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.00098 0C7.55326 0 8.00098 0.447715 8.00098 1V13C8.00098 13.5523 7.55326 14 7.00098 14C6.44869 14 6.00098 13.5523 6.00098 13V1C6.00098 0.447715 6.44869 0 7.00098 0Z"
                />
              </svg>

              <span className="ml-1 md:ml-2 text-12px md:text-14px font-bold leading-5">
                Create Custom Dashboards
              </span>
            </a> */}
          </div>
          <div className="w-2/4 flex items-center justify-end">
            <PlanPayment />
            <ProjectModal />
          </div>
          <div>
            <button
              className={`w-9 h-9 md:w-14 md:h-14 group/hs justify-center shadow-2xl shadow-slate-600 flex items-center border-bg-primaryblue ${
                currentTheme === "light" ? `bg-blue-400` : `bg-white`
              } p-2 rounded-full fixed right-6 md:right-8 bottom-8 z-[999]`}
              onClick={handleTheme}
            >
              <img
                src={currentTheme === "light" ? LightIcon : DarkIcon}
                alt="LightIcon"
              />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PrivateHeader;
