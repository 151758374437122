import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";

const PublicLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="w-full pagemtr-public">
        <PublicHeader />
        {children}
        <PublicFooter />
      </div>
    </>
  );
};

export default PublicLayout;
