import { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { ConfirmationModalProps } from "../../pages/websiteInsights/types/project.types";

export const ConfirmationModal = ({
  element,
  showConfirmationModal,
  setShowConfirmationModal,
  onOk,
}: ConfirmationModalProps) => {
  const [isSame, setIsSame] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === element?.host) {
      setIsSame(true);
    } else {
      setIsSame(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setShowMessage(true);
      }, 10000);
    }
  }, [loading]);
  return (
    <>
      {showConfirmationModal && (
        <Modal
          title={`Delete "${element?.host}" project?`}
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
          isProcessing={loading}
        >
          <div className="p-6">
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Write host name: <b>{element?.host}</b> for confirmation, that
                you want to delete. Once deleted it is not possible to recover.
                We will delete all the records associated with this project,
                such as all URIs, Recommendation Data and Everything - Only
                delete it if you are 100% sure.
              </label>
              <input
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white mt-7"
                placeholder="Write Project Name Here..."
                onChange={handleOnChange}
                required
              />
            </div>

            <button
              type="button"
              className={`mt-5
           text-white bg-red-700 hover:bg-red-800  focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 flex items-center gap-2 ${
             isSame ? "opacity-100" : "opacity-30 cursor-not-allowed"
           } ${loading && "cursor-not-allowed"}`}
              onClick={() => {
                onOk(element.id);
                setLoading(true);
              }}
              disabled={!isSame ? true : loading ? true : false}
            >
              Delete
              {loading && (
                <div className="flex">
                  <svg
                    className="animate-spin -ml-1 mr-2 h-5 w-5 text-white-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 1.647A7.962 7.962 0 0120 12h4c0-6.627-5.373-12-12-12v4c3.042 0 5.824 1.135 7.938 3l-1.647 3z"
                    ></path>
                  </svg>
                </div>
              )}
            </button>

            {showMessage && (
              <h4>It is taking longer than expected, oof! Hold on ⏱️</h4>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
