import "./App.css";
import { Router } from "./routing/Router";

function App() {
  return (
    <div className="w-full h-screen flex flex-cols text-center">
      <Router />
    </div>
  );
}

export default App;
