import React, { useEffect } from "react";
import ReactDom from "react-dom";

interface PropsInterface {
  children: React.ReactNode;
  title: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  shouldBgScroll?: boolean;
  isProcessing?: boolean;
  fullScreen?: boolean;
  dynamicContent?: React.ReactNode;
}

export const Modal = ({
  children,
  title,
  showModal,
  setShowModal,
  shouldBgScroll = false,
  isProcessing = false,
  fullScreen = false,
  dynamicContent = <></>,
}: PropsInterface) => {
  const rootElement = document.getElementById("root") as HTMLElement;
  const closeModal = () => {
    rootElement.style.overflow = "visible";
    setShowModal(false);
  };
  useEffect(() => {
    if (!shouldBgScroll && showModal) {
      rootElement.style.overflow = "hidden";
    } else if (!showModal) {
      closeModal();
    }
    // eslint-disable-next-line
  }, [showModal]);

  if (!showModal) {
    return null;
  }
  return ReactDom.createPortal(
    <>
      <div className="justify-center items-center flex fixed inset-0  p-6 z-[999] w-full h-full top-0 left-0 bg-black/20 backdrop-blur-sm">
        <div
          className={`relative ${
            fullScreen ? "h-full w-full" : "w-650px h-530px"
          } my-6 mx-auto rounded-xl overflow-hidden`}
        >
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slateDark outline-none focus:outline-none">
            <div className="bg-primaryblue/10 text-start px-6 md:px-8 md:pr-3 pr-3 py-3 flex items-center justify-between dark:bg-slate-700">
              <h3 className="text-18px text-black leading-6 font-bold font-poppins dark:text-slate-50">
                {title}
              </h3>

              {!isProcessing && (
                <div className="flex items-center">
                  {dynamicContent}
                  <button
                    className="bg-primaryblue w-26px h-26px rounded-86px"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-white text-22px leading-6 font-normal">
                      ×
                    </span>
                  </button>
                </div>
              )}
            </div>
            <div className="overflow-y-auto pb-5">{children}</div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modal-root") as HTMLElement
  );
};
