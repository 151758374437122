const GlobalLoader = () => {
  return (
    <div className="animated-site-wrap">
      <div className="animated__site__logo">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 307.7 53.2"
        >
          <path
            className="letter__p"
            d="M41.6,20.8c0,4.3-1.5,8.4-4.3,11.4c-3.1,3.4-7.6,5.2-12.9,5.2h-9.9v12.3H2.9V26.5h21.6
	c1.8,0,3.3-0.6,4.3-1.7c0.9-1,1.4-2.4,1.4-3.8c0-2.8-1.8-5.5-5.7-5.5H2.9V4.2h21.7C35.7,4.2,41.6,12.6,41.6,20.8z"
          />
          <polygon
            className="letter__a"
            points="89.2,49.8 76.5,49.8 63.3,19.5 49.9,49.8 37.3,49.8 57.7,4.2 68.8,4.2 "
          />
          <path
            className="letter__mtr"
            d="M305.5,48.7v0.9h-10.4l-0.1-0.1l-13-14.9h-8.5v15h-9V26.5h22.3c5.5,0,7.4-3.7,7.4-6.9
	c0-3.4-2.3-6.9-7.4-6.9h-34.9v36.9h-9V12.7h-12.7v37h-9V17.6l-14.2,19h-1.9l-0.1-0.1l-13.8-18.9v32.1h-9V4.3h10.2l13.8,19.2
	l13.9-19.3h66.6c11.3,0.1,16.4,8,16.4,15.2c0,4.5-1.4,12.1-11.1,14.3L305.5,48.7z"
          />
          <path
            className="letter__g"
            d="M136.8,32.4c-1.2,5.7-4.5,10.6-8.9,14.1c-4.1,3.2-9.3,5.1-15,5.1c-13.6,0-24.6-11-24.6-24.6
	c0-13.6,11-24.6,24.6-24.6c9.5,0,17.8,5.5,21.9,13.5L124.7,21c-2.1-4.3-6.6-7.3-11.8-7.3c-3,0-5.9,1-8.1,2.8
	c-3.2,2.4-5.2,6.2-5.2,10.5c0,7.3,5.9,13.3,13.3,13.3c3,0,5.8-1,8.1-2.8L112.9,27l13,2.9L136.8,32.4z"
          />
          <rect
            className="grey__box"
            x="141.2"
            y="38.6"
            width="34.6"
            height="11"
          />
          <rect
            className="green__box"
            x="141.2"
            y="21.7"
            width="29"
            height="10.7"
          />
          <rect
            className="blue__box"
            x="141.2"
            y="4.4"
            width="23.5"
            height="11.1"
          />
        </svg>
      </div>
    </div>
  );
};
export default GlobalLoader;
