import { combineReducers } from "@reduxjs/toolkit";
import tokenReducers from "./token.reducers";
import projectReducers from "./project.reducer";
import themeReducers from "./theme.reducer";
import pageReducer from "./uris.reducer";
import accountReducer from "./account.reducer";
import sidebarReducer from "./sidebar.reducer";
export const rootReducer = combineReducers({
  token: tokenReducers,
  project: projectReducers,
  theme: themeReducers,
  page: pageReducer,
  account: accountReducer,
  sidebar: sidebarReducer,
});
