import { Modal } from "../../../components/Modal/Modal";
import { Button } from "../../../components/Button/Button";
import { Dispatch, SetStateAction, useEffect } from "react";

const AddPageModal = ({
  showAddPageModal,
  setShowAddPageModal,
  handleSubmit,
  onSubmit,
  projectData,
  register,
  errors,
  buttonLoader,
  reset,
}: {
  showAddPageModal: boolean;
  setShowAddPageModal: Dispatch<SetStateAction<boolean>>;
  handleSubmit: Function;
  onSubmit: Function;
  projectData: { projectName: string };
  register: Function;
  errors: { uri?: { message: string } };
  buttonLoader: boolean;
  reset: Function;
}) => {
  useEffect(() => {
    reset();
  }, [showAddPageModal]);
  return (
    <>
      {" "}
      <Modal
        title="Add Page"
        showModal={showAddPageModal}
        setShowModal={setShowAddPageModal}
      >
        <div className="relative p-6 md:p-8 flex-auto m-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <span className="text-16px leading-6 mb-2 text-primarydakblack font-poppins w-full block font-medium dark:text-white">
              A page URI under the host of <b>{projectData.projectName}</b>
            </span>

            <div className="grid md:grid-cols-1 text-start md:gap-3">
              <div className="mb-2 md:mb-00px">
                <label className="text-16px leading-6 mt-2 mb-2 text-primarydakblack font-poppins w-full block font-medium dark:text-white">
                  URI:
                </label>
                <input
                  type="text"
                  placeholder="/path-name"
                  {...register("uri")}
                  className="bg-primarylightgrey2 border-2 px-4 py-2 rounded-md w-full font-poppins primarylightgrey3 text-16px leading-5 font-medium focus:outline-0 "
                />
                {errors.uri && (
                  <span className="text-red-500">
                    {errors.uri.message as string}
                  </span>
                )}
              </div>
              <label className="text-16px leading-6 mb-2 text-primarydakblack font-poppins w-full block text-xs	dark:text-white">
                A relative URI containing only the path to a specific page with
                / at the beginning.
              </label>

              <Button
                loader={buttonLoader}
                text="Save"
                type="submit"
                onClick={onSubmit}
                className="bg-primaryblue text-white border border-primaryblue hover:bg-white hover:text-primaryblue font-poppins text-14px leading-5 font-bold uppercase text-sm px-6 py-3 rounded-xl"
              ></Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default AddPageModal;
