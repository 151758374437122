import { Button } from "../../../components/Button/Button";
import { Modal } from "../../../components/Modal/Modal";

const CreateOrEditProjectsModal = ({
  handleSubmit,
  onSubmit,
  editMode,
  showModal,
  setShowModal,
  register,
  setProjectData,
  santizeUrl,
  errors,
  buttonLoader,
}: {
  handleSubmit: Function;
  onSubmit: Function;
  editMode: number;
  showModal: boolean;
  setShowModal: any;
  register: Function;
  setProjectData: Function;
  santizeUrl: Function;
  errors: any;
  buttonLoader: boolean;
}) => {
  return (
    <>
      <Modal
        title={editMode ? "Edit Project" : "Add New Project"}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <div className="h-[540px] overflow-y-auto p-6 relative  md:p-4 flex-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-1 text-start md:gap-3">
              <div className="mb-5 md:mb-30px">
                <label className="text-16px leading-6 mb-2 text-primarydakblack dark:text-slate-50 font-poppins w-full block font-medium">
                  Is Project Active?
                </label>
                <select
                  {...register("is_active")}
                  className="bg-primarylightgrey2 px-4 py-2 rounded-md w-full font-poppins text-primaryblue text-16px leading-5 font-medium focus:outline-0"
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
            </div>

            <div className="grid md:grid-cols-1 text-start md:gap-3">
              <div className="mb-5 md:mb-30px">
                <label className="text-16px leading-6 mb-2 text-primarydakblack dark:text-slate-50 font-poppins w-full block font-medium">
                  Protocol
                </label>
                <select
                  {...register("protocol")}
                  className="bg-primarylightgrey2 px-4 py-2 rounded-md w-full font-poppins text-primaryblue text-16px leading-5 font-medium focus:outline-0"
                >
                  <option value="http">HTTP</option>
                  <option value="https">HTTPS</option>
                </select>
              </div>
            </div>

            <div className="grid md:grid-cols-1 text-start md:gap-3">
              <div className="mb-5 md:mb-30px">
                <label className="text-16px leading-6 mb-2 text-primarydakblack dark:text-slate-50 font-poppins w-full block font-medium">
                  Host
                </label>
                <input
                  type="text"
                  placeholder="pagemtr.com"
                  className="bg-primarylightgrey2 px-4 py-2 rounded-md w-full font-poppins primarylightgrey3 text-16px leading-5 font-medium focus:outline-0 "
                  {...register("host")}
                  disabled={editMode ? true : false}
                  onBlur={(e) =>
                    setProjectData((prev: any) => ({
                      ...prev,
                      host: santizeUrl(e.target.value),
                    }))
                  }
                />
                {errors.host && (
                  <span className="text-red-500">
                    {errors.host.message as string}
                  </span>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-1 text-start md:gap-3">
              <div className="mb-5 md:mb-30px">
                <label className="text-16px leading-6 mb-2 text-primarydakblack dark:text-slate-50 font-poppins w-full block font-medium">
                  Reporting Frequency
                </label>
                <select
                  {...register("reporting_frequency")}
                  className="bg-primarylightgrey2 px-4 py-2 rounded-md w-full font-poppins text-primaryblue text-16px leading-5 font-medium focus:outline-0"
                >
                  <option value={0}>Reporting Disabled</option>
                  <option value={1}>Daily</option>
                  <option value={2}>Weekly</option>
                  <option value={3}>Monthly</option>
                </select>
              </div>
            </div>
            <div className="flex gap-3 items-center justify-center  rounded-b">
              <button
                className="bg-white text-primaryblue border border-primaryblue hover:bg-primaryblue hover:text-white font-poppins text-14px leading-5 font-bold uppercase text-sm px-6 py-3 rounded-xl"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <Button
                loader={buttonLoader}
                text="Save"
                type="submit"
                onClick={onSubmit}
                className="bg-primaryblue text-white border border-primaryblue hover:bg-white hover:text-primaryblue font-poppins text-14px leading-5 font-bold uppercase text-sm px-6 py-3 rounded-xl"
              ></Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CreateOrEditProjectsModal;
