import * as yup from "yup";
export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    .required("Email Field is required!"),
  domain: yup.string().required("Domain is required"),
});

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    .required("Email field is required!"),
  password: yup.string().required("Password is required!"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    .required("Email field is required!"),
});

export const createProjectValidationSchema = yup.object().shape({
  host: yup.string().required("Host is required!"),
});

export const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password Field is Required!")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{4,10}$/,
      "Password must be 4-10 characters long and contain at least one digit and one special character"
    ),
  retypePassword: yup
    .string()
    .required("Retype Password Field is Required!")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

export const createProjectAnnotation = yup.object().shape({
  annotation: yup
    .string()
    .max(255, "Annotation should be less than 255 characters.")
    .required("Annotation is required!"),
});

export const addNewURISchema = yup.object().shape({
  uri: yup
    .string()
    .matches(/^\//, 'URI must begin with "/"')
    .required("The URI is Required!"),
});
