import { AxiosError } from "axios";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../../../axios/method";
import { CreateProject, ProjectType } from "../types/project.types";

export const createProject = async (data: CreateProject) => {
  let response = await axiosPost("/project", { ...data });
  return response;
};

export const UpdateProject = async (updatedata: ProjectType) => {
  try {
    let { data } = await axiosPut("/project", { ...updatedata });

    if (data && data !== null) {
      return data;
    }
  } catch (error: unknown) {
    return (error as AxiosError).response;
  }
};

export const deleteProject = async (deletableId: number) => {
  try {
    let response = await axiosDelete(`/delete/project/${deletableId}`);
    return response;
  } catch (error: unknown) {
    return (error as AxiosError).response;
  }
};

export const getAllProjectsForUser = async () => {
  try {
    let { data } = await axiosGet("/users/project");
    if (data && data !== null) {
      return data;
    }
  } catch (error: unknown) {
    return (error as AxiosError).response;
  }
};
