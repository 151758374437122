import OhNo from "../../assets/oh-no.jpg";

export const ErrorBoundryMessage = () => {
  return (
    <>
      <div className="flex justify-center dark:bg-slateDark">
        <img src={OhNo} alt="Went Wrong" height={600} width={500} />
      </div>
      <h3>
        Okay, sometimes this happens. An Error Occurs! Check if you can resolve
        it by reloading the app?
      </h3>
    </>
  );
};
