import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showPages } from "../pages/websiteInsights/services/pages.services";

const initialState = {
  page_id: 0,
  page_uuid: "",
  project_id: 0,
  uri: "",
};

export const handleGetPages = createAsyncThunk(
  "/page/select",
  async (projectId: number) => {
    try {
      const response = await showPages({ project_id: projectId });
      return response?.data.data[0];
    } catch (err) {
      return err;
    }
  }
);
const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setURI: (state, action) => {
      state.page_id = parseInt(action.payload.id);
      state.page_uuid = action.payload.uuid;
      state.uri = action.payload.uri;
      state.project_id = action.payload.project_id;
    },
    resetURI: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleGetPages.fulfilled, (state, action) => {
      if (action?.payload) {
        if (state.page_id === 0) {
          state.page_id = parseInt(action.payload.id);
          state.page_uuid = action.payload.uuid;
          state.uri = action.payload.uri;
          state.project_id = action.payload.project_id;
        }
      }
    });
  },
});

export default pageSlice.reducer;
export const { setURI, resetURI } = pageSlice.actions;
