import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SitelogoWhite from "../../assets/pagemtr-white.svg";
import Sitelogo from "../../assets/pagemtr-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setTheme } from "../../reducers/theme.reducer";
import LightIcon from "../../assets/light.svg";
import DarkIcon from "../../assets/night-mode.svg";
const PublicHeader = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentTheme = useSelector((state: RootState) => state.theme.theme);
  const handleTheme = () => {
    if (currentTheme === "light") {
      dispatch(setTheme("dark"));
    } else {
      dispatch(setTheme("light"));
    }
  };

  return (
    <header className="pagemtr-header bg-white z-[999] py-26px px-4 dark:bg-slate-700">
      <div className="container m-auto">
        <nav>
          <div className="flex flex-wrap items-center justify-between mx-auto">
            <a className="flex items-center xl:w-2/5">
              {currentTheme === "light" ? (
                <img src={Sitelogo} alt="Sitelogo" className="w-[190px]" />
              ) : (
                <img src={SitelogoWhite} alt="Sitelogo" className="w-[190px]" />
              )}
            </a>
            <button
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <div
              className="hidden xl:flex w-3/5 items-center"
              id="navbar-default"
            >
              <ul className="flex p-4 md:p-0 flex-row space-x-12 font-semibold text-15px leading-6 tracking-wide text-primaryblue ">
                <li className="relative">
                  <a href="javascript:void(0)" className="peer px-5 py-3 ">
                    Features
                    <i className="icon-drop-down text-[8px] pl-2"></i>
                  </a>

                  <div className="absolute top-8 min-w-[300px] text-left z-10 hidden peer-hover:flex hover:flex w-[200px]  flex-col bg-white drop-shadow-lg">
                    <a
                      className="px-5 py-2 hover:bg-gray-200"
                      target="_blank"
                      href="https://pagemtr.com/website-malware-virus-protection-service"
                    >
                      Website Virus Potection
                    </a>
                    <a
                      className="px-5 py-2 hover:bg-gray-200"
                      target="_blank"
                      href="https://pagemtr.com/uptime-monitoring"
                    >
                      Uptime Monitoring Service
                    </a>
                    <a
                      className="px-5 py-2 hover:bg-gray-200"
                      target="_blank"
                      href="https://pagemtr.com/core-web-vitals-monitoring"
                    >
                      Core Web Vitals Monitoring
                    </a>
                    <a
                      className="px-5 py-2 hover:bg-gray-200"
                      target="_blank"
                      href="https://pagemtr.com/website-safety-monitoring"
                    >
                      Website Safety Monitoring
                    </a>
                    <a
                      className="px-5 py-2 hover:bg-gray-200"
                      target="_blank"
                      href="https://pagemtr.com/events-monitoring"
                    >
                      Event Monitoring
                    </a>

                    <a
                      className="px-5 py-2 hover:bg-gray-200"
                      target="_blank"
                      href="https://pagemtr.com/playground-online-optimization-tool"
                    >
                      Playground
                    </a>
                  </div>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://pagemtr.com/#pricing"
                    className="block"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://pagemtr.com/about"
                    className="block"
                  >
                    About
                  </a>
                </li>
              </ul>
              <ul className="font-medium flex p-4 md:p-0 flex-row ml-auto items-center gap-3">
                <li>
                  <button
                    type="button"
                    className={`border border-primaryblue ${
                      pathname === "/login"
                        ? "bg-primaryblue text-white"
                        : "text-primaryblue"
                    } px-7 py-2.5 rounded-xl text-15px leading-6 tracking-wide font-semibold hover:bg-primaryblue hover:text-white`}
                    onClick={() => navigate("/login")}
                  >
                    Sign In
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`border border-primaryblue ${
                      pathname === "/signup"
                        ? "bg-primaryblue text-white "
                        : "text-primaryblue "
                    } px-7 py-2.5 rounded-xl text-15px leading-6 tracking-wide font-semibold hover:bg-primaryblue hover:text-white`}
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </button>
                </li>
                <li>
                  <div>
                    <button
                      className={`w-9 h-9 md:w-14 md:h-14 group/hs justify-center shadow-2xl shadow-slate-600 flex items-center border-bg-primaryblue ${
                        currentTheme === "light" ? `bg-blue-400` : `bg-white`
                      } p-2 rounded-full fixed right-8 bottom-8 z-[999]`}
                      onClick={handleTheme}
                    >
                      <img
                        src={currentTheme === "light" ? LightIcon : DarkIcon}
                        alt="LightIcon"
                      />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default PublicHeader;
