import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { Modal } from "../../../components/Modal/Modal";

export const LogoutConfirmation = ({
  showConfirmationModal,
  setShowConfirmationModal,
  handleLogout,
}: {
  showConfirmationModal: boolean;
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
  handleLogout: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <>
      <Modal
        title={``}
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
      >
        <>
          <h3 className="text-center text-lg text-black font-bold mt-3 dark:text-white">
            Are you sure you want to logout ?
          </h3>
          <div className="flex flex-row gap-2 mt-8 justify-center">
            <button
              type="button"
              className="bg-primaryblue mt-3 text-white border border-primaryblue font-poppins text-14px leading-5 font-bold uppercase text-sm px-6 py-3 rounded-lg"
              onClick={handleLogout}
            >
              Logout
            </button>
            <button
              type="button"
              className="bg-primarylightgrey2 mt-3 text-primaryblue border border-primaryblue font-poppins text-14px leading-5 font-bold uppercase text-sm px-6 py-3 rounded-lg"
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancel
            </button>
          </div>
        </>
      </Modal>
    </>
  );
};
