import { useState } from "react";
import PlanPaymentModel from "./PlanPaymentModel";


const PlanPayment = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowPlanPaymentModel = () => {
    setShowModal(true);
  };

  return (
    <>
      <div>
        <button
          id="plan-payment"
          type="button"
          onClick={() => {
            handleShowPlanPaymentModel();
          }}
          className="w-9 h-9 md:w-10 md:h-10 mr-2 md:mr-3 group/hs relative text-white flex items-center border-solid border-2 border-primaryblue border-bg-primaryblue hover:bg-white hover:text-primaryblue bg-primaryblue text-14px font-bold  p-2 leading-7 rounded-md group/hs"
        >
          <svg
            width="24"
            height="21"
            className="fill-white group-hover/hs:fill-primaryblue"
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.4531 5.97243V4.47913H1.4933C0.670493 4.47913 0 5.14962 0 5.97243V8.95904H10.7682C10.5606 8.04663 10.4531 7.06403 10.4531 5.97243Z" />
            <path d="M23.8316 11.9457C22.8132 13.5151 21.2602 14.8502 19.0351 16.1284C18.6932 16.324 18.3079 16.4256 17.9196 16.4256C17.5314 16.4256 17.1461 16.324 16.8086 16.1314C14.5836 14.8502 13.0306 13.5136 12.0106 11.9457H0V19.4122C0 20.2365 0.670493 20.9055 1.4933 20.9055H22.3996C23.2239 20.9055 23.8929 20.2365 23.8929 19.4122V11.9457H23.8316ZM6.71987 16.4256H3.73326C3.32111 16.4256 2.98661 16.0911 2.98661 15.6789C2.98661 15.2668 3.32111 14.9323 3.73326 14.9323H6.71987C7.13202 14.9323 7.46652 15.2668 7.46652 15.6789C7.46652 16.0911 7.13202 16.4256 6.71987 16.4256Z" />
            <path d="M23.4402 2.30043L18.2137 0.0604788C18.024 -0.0201596 17.8135 -0.0201596 17.6238 0.0604788L12.3973 2.30043C12.124 2.41691 11.9463 2.6872 11.9463 2.98586V5.97247C11.9463 10.0805 13.465 12.4818 17.5477 14.8337C17.6627 14.8994 17.7911 14.9323 17.9195 14.9323C18.0479 14.9323 18.1764 14.8994 18.2913 14.8337C22.374 12.4878 23.8927 10.0865 23.8927 5.97247V2.98586C23.8927 2.6872 23.715 2.41691 23.4402 2.30043ZM20.7433 5.69322L17.7567 9.42648C17.6134 9.60269 17.3983 9.70573 17.1729 9.70573C17.1624 9.70573 17.1505 9.70573 17.1415 9.70573C16.9041 9.69527 16.6845 9.57282 16.5516 9.37421L15.0583 7.13426C14.8299 6.7908 14.9224 6.32787 15.2659 6.0994C15.6064 5.87242 16.0708 5.96202 16.3008 6.30697L17.2281 7.69723L19.5756 4.7614C19.8339 4.44034 20.3043 4.38957 20.6254 4.64492C20.9479 4.90028 20.9987 5.37067 20.7433 5.69322Z" />
          </svg>
        </button>
      </div>
      {showModal && (
        <>
          <PlanPaymentModel showModal={showModal} setShowModal={setShowModal} />
        </>
      )}
    </>
  );
};

export default PlanPayment;
