import { AxiosError, AxiosResponse } from "axios";
import { axiosDelete, axiosPost, axiosPut } from "../../../axios/method";

export const showPages = async (data: { project_id: number }) => {
  try {
    let response = await axiosPost("/project/pages/find", { ...data });
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};

export const addPage = async (data: {
  uri: string;
  project_id: number;
  test_accessibility_total_time_avg: number;
}): Promise<AxiosResponse> => {
  let response = await axiosPost("/project/pages", { ...data });
  return response;
};

export const updatePage = async (data: any) => {
  try {
    let response = await axiosPut("/project/pages", { ...data });
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};

export const deletePage = async (id: number) => {
  try {
    let response = await axiosDelete(`/delete/page/${id}`);
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};
