import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export const showToast = (
  message: string,
  type: string,
  duration: number = 3000,
  dismissable: boolean = false
) => {
  const notyf = new Notyf({
    duration: duration,
    position: {
      x: "right",
      y: "top",
    },
  });
  switch (type) {
    case "success":
      notyf.success({ message: message, dismissible: dismissable });
      break;
    case "error":
      notyf.error({ message: message, dismissible: dismissable });
      break;
    default:
      break;
  }
};
