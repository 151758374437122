import { useEffect, useState } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createProjectAnnotation } from "../../../helpers/validation-schema";
import {
  createAnnotation,
  deleteAnnotation,
  getAnnotation,
  updateAnnotation,
} from "../services/annotation.service";
import "../../../assets/css/style.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

import { Loading } from "../../../components/Loader/Loading";
import { Button } from "../../../components/Button/Button";
import { showToast } from "../../../helpers/notification";
import { NoData } from "../../../components/NoData/NoData";

export const WorklogModal = () => {
  const INITIAL_DATA = {
    id: 0,
    annotation: "",
  };

  interface AnnotationInterface {
    id?: number;
    annotation: string;
  }

  const projectData = useSelector((state: RootState) => state.project);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<AnnotationInterface>(INITIAL_DATA);
  const [editMode, setEditMode] = useState(0);
  const [annotationData, setAnnotationData] = useState([]);
  const [showTimeLine, setShowTimeLine] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const values = data;
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<AnnotationInterface>({
    values,
    resolver: yupResolver(createProjectAnnotation),
  });

  interface ProjectAnnotationAttribute {
    id?: number;
    uuid?: string;
    annotation: string;
    created_at: string;
    updated_at: number;
    is_displayed_on_charts: number;
    display_at: number;
    project_id: number;
    account_id: number;
  }

  const getAnnotationData = async () => {
    const annotations = await getAnnotation(projectData.projectUUID);
    setAnnotationData(annotations?.data?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (showModal) {
      getAnnotationData();
    }
    // eslint-disable-next-line
  }, [projectData]);

  const onSubmit = async (annotationData: AnnotationInterface) => {
    setButtonLoader(true);
    if (editMode) {
      setButtonLoader(true);
      const update = await updateAnnotation({
        annotation: annotationData.annotation,
        project_id: projectData.projectId,
        id: data.id ? data.id : 0,
      });

      if (update?.status === 200) {
        showToast(
          update.data.message,
          update.data.message === "failed" ? "error" : "success"
        );
        setShowTimeLine(true);
        getAnnotationData();
      }
      setButtonLoader(false);

      return;
    } else {
      setButtonLoader(true);
      const addAnnotation = await createAnnotation({
        annotation: annotationData.annotation,
        project_id: projectData.projectId,
      });

      if (addAnnotation?.status === 200) {
        showToast(
          addAnnotation.data.message,
          addAnnotation.data.message === "failed" ? "error" : "success"
        );
        setShowTimeLine(true);
        getAnnotationData();
      }
    }
    setButtonLoader(false);
  };

  const handleDeleteWorklog = async (id: number) => {
    const response = await deleteAnnotation(id);
    if (response?.status === 200) {
      const updatedAnnotation = [...annotationData];
      const newUpdatedData = updatedAnnotation.filter(
        (eachAnnotationId: { id: number }) => {
          return eachAnnotationId.id !== id;
        }
      );
      setAnnotationData(newUpdatedData);
    }
  };

  return (
    <>
      <button
        type="button"
        className="w-full bg-white text-12px leading-4 font-bold text-black rounded-lg px-4 py-3"
        onClick={() => {
          setShowTimeLine(false);
          setShowModal(true);
        }}
      >
        Worklog
      </button>
      {showModal && (
        <Modal
          title="Worklog"
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <div className="relative p-6 md:p-4 flex-auto m-2">
            <div>
              <button
                type="button"
                onClick={() => {
                  setValue("annotation", "");
                  setEditMode(0);
                  setShowTimeLine(false);
                }}
                className={`${
                  !showTimeLine
                    ? "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    : "py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                } `}
              >
                New
              </button>
              <button
                type="button"
                onClick={() => {
                  getAnnotationData();
                  setShowTimeLine(true);
                  setData({ ...INITIAL_DATA });
                  setIsLoading(true);
                  reset({ ...INITIAL_DATA });
                }}
                className={`${
                  showTimeLine
                    ? "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    : "py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                } `}
              >
                Show TimeLine
              </button>
            </div>
            {!showTimeLine ? (
              <>
                <span className="text-12px leading-6 mb-2 text-primarydakblack font-poppins w-full block font-medium dark:text-slate-50">
                  What have you done?
                </span>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    id="message"
                    // rows={4}
                    {...register("annotation")}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 dark:bg-slate-700 focus:ring-blue-500 focus:border-blue-500 dark:border-slate-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your logs here..."
                  ></input>
                  {errors.annotation && (
                    <span className="text-red-500">
                      {errors.annotation.message as string}
                    </span>
                  )}
                  <span className="text-12px leading-5 mt-4 mb-2 text-primarydakblack font-poppins w-full block font-medium dark:text-slate-50">
                    Shortly describe a step you've done to test or improve the
                    results. You may note the steps you are going to execute to
                    perform the experiment.
                  </span>
                  <div className="relative flex-auto justify-between items-center">
                    <span className="text-12px text-primarydakblack font-arial dark:text-slate-50">
                      Set a date of the change (current as default)
                    </span>
                  </div>
                  <Button
                    loader={buttonLoader}
                    text="Save"
                    type="submit"
                    onClick={onSubmit}
                    className="bg-primaryblue mt-3 text-white border border-primaryblue font-poppins text-14px leading-5 font-bold uppercase text-sm px-6 py-3 rounded-xl "
                  ></Button>
                </form>
              </>
            ) : (
              <>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {annotationData && annotationData.length > 0 ? (
                      <div className="pagemtr-timeline  relative before:content-[''] before:absolute before:w-[2px] before:h-full before:bg-black/50 dark:before:bg-slate-400 before:left-3 md:before:left-2/4">
                        <ul className="mt-5 h-80 overflow-y-auto overflow-hidden">
                          {annotationData.map(
                            (data: ProjectAnnotationAttribute) => {
                              return (
                                <li className="mb-6 w-2/4 relative px-4 pl-9 pb-5 md:pl-8 md:px-7 md:py-4">
                                  <span className="timeline-icon absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-4 md:ring-8 ring-white dark:ring-slate-700 dark:bg-blue-700">
                                    <svg
                                      aria-hidden="true"
                                      className="w-3 h-3 text-blue-800 dark:text-blue-200"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                  <h3 className="mb-2 break-words text-16px leading-5 font-semibold text-darkgreyone dark:text-slate-50">
                                    {data.annotation}
                                  </h3>
                                  <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-slate-400">
                                    {new Date(
                                      parseInt(data.created_at)
                                    ).toLocaleString()}
                                  </time>
                                  <div className="action-btn flex items-center gap-3 mt-3">
                                    <button
                                      type="button"
                                      className="bg-slate-500 px-3 py-1 rounded-md tracking-wider text-12px leading-4 text-white"
                                      onClick={() => {
                                        setData({
                                          id: data.id ? data.id : undefined,
                                          annotation: data.annotation,
                                        });
                                        setEditMode(1);
                                        setShowTimeLine(false);
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => {
                                        if (data.id) {
                                          handleDeleteWorklog(data.id);
                                        }
                                      }}
                                      type="button"
                                      className="bg-red-600 px-3 py-1 rounded-md tracking-wider text-12px leading-4 text-white"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    ) : (
                      <NoData text="No Data Found" />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
