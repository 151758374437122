import { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "./Modal";

interface ConfirmationProps {
  element: { id: number; name: string };
  showConfirmationModal: boolean;
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
  onOk: Function;
  message?: string;
}
export const NormalConfirmation = ({
  element,
  showConfirmationModal,
  setShowConfirmationModal,
  onOk,
  message,
}: ConfirmationProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {showConfirmationModal && (
        <Modal
          title={`Are you sure you want to delete "${element.name}" ?`}
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
        >
          <div className="p-6">
            <p className="dark:text-slate-100">{message ? message : ""}</p>
            <div className="flex">
              <button
                type="button"
                className={`mt-5
              text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 flex items-center gap-2 ${
                loading ? "cursor-not-allowed" : "cursor-pointer"
              }`}
                disabled={loading ? true : false}
                onClick={() => {
                  onOk(element.id);
                  setLoading(true);
                }}
              >
                Delete
                {loading && (
                  <div className="flex">
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5 text-white-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 1.647A7.962 7.962 0 0120 12h4c0-6.627-5.373-12-12-12v4c3.042 0 5.824 1.135 7.938 3l-1.647 3z"
                      ></path>
                    </svg>
                  </div>
                )}
              </button>
              <button
                onClick={() => setShowConfirmationModal(false)}
                className="cursor-pointer mt-5
              text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
