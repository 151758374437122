import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TokenType } from "../types/generalTypes";

const defaultValue = localStorage.getItem("authToken");
const initialState: TokenType = { token: defaultValue };

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export default tokenSlice.reducer;
export const { setToken } = tokenSlice.actions;
