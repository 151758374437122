import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllProjectsForUser } from "../pages/websiteInsights/services/projects.service";
import { ProjectType } from "../pages/websiteInsights/types/websiteInsitghts.types";

const initialState: ProjectType = {
  projectId: 0,
  projectName: "No Project Created",
  projectUUID: "",
  pageId: 0,
};

export const handleGetAllProjects = createAsyncThunk(
  "/projectData/fetch",
  async (uuid: string | undefined) => {
    try {
      const response = await getAllProjectsForUser();
      if (uuid && uuid) {
        const newResponse = response.data.find(
          (item: { uuid: string }) => item.uuid === uuid
        );
        if (newResponse) {
          return newResponse;
        } else {
          return response.data[response.data.length - 1];
        }
      } else {
        return response.data[response.data.length - 1];
      }
    } catch (err) {
      return err;
    }
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, action) => {
      if (action.payload) {
        return action.payload;
      }
      return state;
    },
    resetProject: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleGetAllProjects.fulfilled, (state, action) => {
      if (action.payload) {
        state.projectId = action.payload.id;
        state.projectName = action.payload.host;
        state.projectUUID = action.payload.uuid;
      } else {
        state.projectId = 0;
        state.projectName = "No Projects Created";
        state.projectUUID = "";
      }
    });
  },
});

export default projectSlice.reducer;
export const { setProject, resetProject } = projectSlice.actions;
