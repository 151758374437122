import { AxiosError } from "axios";
import { axiosGet, axiosPost } from "../../../axios/method";
import { AccountDataProps } from "../types/websiteInsitghts.types";

export const getYourAccount = async () => {
  try {
    let { data } = await axiosGet("/perticular/account");

    if (data && data !== null) {
      return data;
    }
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};

export const getPlanPaymentDetails = async (data: AccountDataProps) => {
  try {
    let response = await axiosPost("/account/plan/details", {
      ...data,
    });
    return response;
  } catch (err: unknown) {
    return (err as AxiosError).response;
  }
};
