import { createSlice } from "@reduxjs/toolkit";
const initialState: { id: number; uuid: string; plan_id: number } = {
  id: 0,
  uuid: "",
  plan_id: 1,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.id = action.payload.id;
      state.uuid = action.payload.uuid;
    },
    setAccountPlan: (state, action) => {
      state.plan_id = action.payload;
    },
    resetAccount: () => {
      return { ...initialState };
    },
  },
});

export default accountSlice.reducer;
export const { setAccount, resetAccount, setAccountPlan } =
  accountSlice.actions;
