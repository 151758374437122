import { useSelector } from "react-redux";
import Sitelogo from "../../assets/pagemtr-logo.svg";
import SitelogoWhite from "../../assets/pagemtr-white.svg";

import { RootState } from "../../store";

const PublicFooter = () => {
  const currentTheme = useSelector((state: RootState) => state.theme.theme);

  return (
    <footer className="site-footer  py-50px px-3 bg-white dark:bg-slate-700">
      <div className="container mx-auto">
        <div className="flex flex-wrap text-left flex-row">
          <div className="w-full md:w-2/4 xl:w-[35%] pr-3 pl-3 mb-7 xl:mb-0 overflow-hidden">
            {/* <img src={Sitelogo} className="w-40" alt="Sitelogo" /> */}
            {currentTheme === "light" ? (
              <img src={Sitelogo} alt="Sitelogo" className="w-[190px]" />
            ) : (
              <img src={SitelogoWhite} alt="Sitelogo" className="w-[190px]" />
            )}
            <p className="text-20px leading-7 text-darkgrey font-normal font-poppins mt-6 xl:mt-10 dark:text-slate-50">
              Website optimization
              <br /> made easy!
            </p>
            <p className="align-bottom xl:h-full text-20px leading-7 text-darkgrey dark:text-slate-50 font-normal font-poppins mt-5 xl:mt-10 flex items-center">
              <span className="ml-2">All rights reserved.</span>
            </p>
          </div>
          <div className="w-full md:w-2/4 xl:w-[25%] pr-3 pl-3 mb-7 xl:mb-0">
            <h6 className="font-poppins text-25px leading-8 text-primaryblue font-semibold">
              Features
            </h6>
            <ul className="text-20px leading-7 font-poppins font-normal text-darkgrey dark:text-slate-50 mt-5 xl:mt-10">
              <li className="mb-5 xl:mb-7">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://pagemtr.com/website-malware-virus-protection-service"
                >
                  Website Virus Potection
                </a>
              </li>
              <li className="mb-5 xl:mb-7">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://pagemtr.com/uptime-monitoring"
                >
                  Uptime Monitoring Service
                </a>
              </li>
              <li className="mb-5 xl:mb-7">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://pagemtr.com/core-web-vitals-monitoring"
                >
                  Core Web Vitals Monitoring{" "}
                </a>
              </li>
              <li className="mb-5 xl:mb-7">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://pagemtr.com/website-safety-monitoring"
                >
                  Website Safety Monitoring{" "}
                </a>
              </li>
              <li className="mb-5 xl:mb-7">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://pagemtr.com/events-monitoring"
                >
                  Event Monitoring{" "}
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://pagemtr.com/playground-online-optimization-tool"
                >
                  {" "}
                  Playground
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-2/4 xl:w-1/5 pr-3 pl-3 mb-5 md:mb-0 xl:mb-0">
            <h6 className="font-poppins text-25px leading-8 text-primaryblue font-semibold">
              Social
            </h6>
            <ul className="text-20px leading-7 font-poppins font-normal text-darkgrey dark:text-slate-50 mt-5 xl:mt-10">
              <li className="mb-5 xl:mb-7">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/pagemtr"
                >
                  Linked In
                </a>
              </li>
              <li className="mb-5 xl:mb-7">
                <a rel="noreferrer" href="#">
                  Instagram
                </a>
              </li>
              <li>
                <a rel="noreferrer" href="#">
                  Facebook{" "}
                </a>
              </li>
            </ul>
            <h6 className="font-poppins text-25px leading-8 text-primaryblue font-semibold mt-10 xl:mt-68px">
              Support
            </h6>
            <ul className="text-20px leading-7 font-poppins font-normal text-darkgrey dark:text-slate-50 mt-5 xl:mt-10">
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="mailto:admin@pagemtr.com"
                >
                  admin@pagemtr.com
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-2/4 xl:w-1/5 pl-3 pr-3 mb-5 md:mb-0 xl:mb-0">
            <h6 className="font-poppins text-25px leading-8 text-primaryblue font-semibold">
              Legal
            </h6>
            <ul className="text-20px leading-7 font-poppins font-normal text-darkgrey dark:text-slate-50 mt-5 xl:mt-10">
              <li className="mb-5 xl:mb-7">
                <a href="#">Terms of Use</a>
              </li>
              <li className="mb-5 xl:mb-7">
                <a href="#">Privace Policy</a>
              </li>
              <li>
                <a href="#">Legal Notice </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PublicFooter;
