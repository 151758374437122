import { Outlet } from "react-router-dom";
import PrivateHeader from "./PrivateHeader";
import PrivateFooter from "./PrivateFooter";
import Sidebar from "../Sidebar";
import Joyride, { Step } from "react-joyride";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundryMessage } from "../../components/ErrorBoundry/OhNo";
interface State {
  run: boolean;
  steps: Step[];
}
const PrivateLayout = ({ children }: { children: React.ReactNode }) => {
  const [shouldShow, setShouldShow] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("showIntro")) {
      setShouldShow(true);
    }
  }, []);
  const { project_id } = useSelector((state: RootState) => ({
    project_id: state.project.projectId,
  }));
  const [{ steps }] = useState<State>({
    run: shouldShow,
    steps: [
      {
        content: (
          <h2>
            Wohoo! Thank you for joining us! Let us introduce to you this
            platform!
          </h2>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: <h2>From here, you can create new projects!</h2>,
        placement: "top",
        target: "#add-project",
        title: "Create a New Project",
      },
    ],
  });

  return (
    <>
      {project_id !== 0 && (
        <Joyride
          continuous
          callback={(data) => {
            if (data.status === "finished" || data.status === "skipped") {
              localStorage.setItem("showIntro", "1");
              setShouldShow(false);
            }
          }}
          run={shouldShow}
          steps={steps}
          hideCloseButton
          scrollToFirstStep
          showSkipButton
          showProgress={false}
          styles={{
            options: {
              primaryColor: "#2E9EFF",
            },
          }}
        />
      )}
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="hidden p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      <>
        <Sidebar />
        <div className=" sm:ml-72 w-full dark:bg-slate-600">
          <PrivateHeader />
          <ErrorBoundary fallback={<ErrorBoundryMessage />}>
            {children}
            <Outlet />
            <PrivateFooter />
          </ErrorBoundary>
        </div>
      </>
    </>
  );
};

export default PrivateLayout;
