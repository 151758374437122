import { SidebarType } from "../types/generalTypes";

export const sidebarDetails: SidebarType[] = [
  {
    name: "Website Insights",
    icon: "icon-website-Insight",
    path: "",
  },
  {
    name: "Quick Status",
    icon: "icon-quick-status",
    path: "/quickstatus",
  },
  {
    name: "Static Resources",
    icon: "icon-static-resources",
    path: "/static",
  },
  {
    name: "Javascript Profiler",
    icon: "icon-javascript-profiler",
    path: "/javascript",
  },
  {
    name: "Resource Profiler",
    icon: "icon-resources-profiler",
    path: "/resources-profiler",
  },
  {
    name: "Recommendation",
    icon: "icon-recommendation",
    path: "/recommendation",
  },
  {
    name: "Website Safety",
    icon: "icon-website-safety",
    path: "/websitesafety",
  },
  {
    name: "Events Monitor",
    icon: "icon-events-monitor",
    path: "/events-monitor",
  },
  {
    name: "Web Crawler",
    icon: "icon-web-crawler",
    path: "/webcrawler",
  },

  // {
  //   name: "Playground",
  //   icon: "icon-playground",
  //   path: "/playground",
  // },
];

export const cols: string[] = ["Time", "Event Level", " Total Time"];
