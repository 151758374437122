import EditIcon from "../../../assets/edit-icon.svg";
import DeleteIcon from "../../../assets/trash.svg";
import { Loading } from "../../../components/Loader/Loading";
import { Modal } from "../../../components/Modal/Modal";
import { ShowProjectModalProps } from "../types/showProjectModal.types";

const ShowProjectsModal = ({
  showModal,
  setShowModal,
  handleSearch,
  dataFound,
  projects,
  projectInfo,
  handleSetProject,
  handleOpenEditDataModel,
  handleDeleteProject,
}: ShowProjectModalProps) => {
  return (
    <>
      <Modal
        title="Show Projects"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <div className="relative p-6 md:p-8 flex-auto">
          <form>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                className="block w-full p-2 pl-11 bg-primarylightgrey2 rounded-lg text-primarylightgrey3 text-16px leading-5 focus:outline-0"
                placeholder="Search by host name.."
                onChange={handleSearch}
                required
              />
            </div>
          </form>
          <div className="search-results">
            <ul className="mt-2 mb-3">
              {dataFound}
              {projects.data.length > 0 ? (
                projects.data.map((eachProject) => {
                  return (
                    <li
                      key={eachProject.id}
                      className={`flex items-center cursor-pointer justify-between border-b border-b-black/10 dark:border-slate-500 py-3 text-primarydakblack dark:text-slate-50 font-medium text-16px leading-6 hover:bg-gray-100 dark:hover:bg-slate-600  p-2 ${
                        projectInfo === eachProject.id && "bg-blue-500/20"
                      } `}
                      onClick={() => handleSetProject(eachProject)}
                    >
                      <div className={`flex gap-3 items-center`}>
                        <span
                          className={`w-4 h-4 ${
                            eachProject.is_active
                              ? "bg-green-600"
                              : "bg-gray-600"
                          } rounded-xl`}
                        ></span>
                        <p>{eachProject.host}</p>
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={(e) => {
                            handleOpenEditDataModel(e, eachProject);
                          }}
                        >
                          <img src={EditIcon} alt="editicon" />
                        </button>

                        <button
                          onClick={(e) => {
                            handleDeleteProject(e, eachProject as any);
                          }}
                        >
                          <img src={DeleteIcon} alt="editicon" />
                        </button>
                      </div>
                    </li>
                  );
                })
              ) : (
                <Loading />
              )}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShowProjectsModal;
