import NoDataImage from "../../assets/no-data.svg";
interface PropsState {
  text: string;
}
export const NoData = ({ text }: PropsState) => {
  return (
    <div className="flex flex-col items-center justify-center dark:bg-slateDark py-2 bg-white ">
      <img src={NoDataImage} alt="" className="w-72" />
      <h3 className="text-black text-base font-semibold dark:text-slate-50 mt-3 text-center">
        {text}
      </h3>
    </div>
  );
};
