import { lazy } from "react";
import { RouteInterface } from "../types/generalTypes";

export const routes: RouteInterface[] = [
  {
    path: "/project/:uuid/quickstatus",
    element: lazy(() => import("../pages/quickStatus/QuickStatus")),
    isPrivate: true,
  },
  {
    path: "/project/:uuid",
    element: lazy(() => import("../pages/websiteInsights/WebsiteInsightsPage")),
    isPrivate: true,
    index: true,
  },
  {
    path: "/project/:uuid/static",
    element: lazy(() => import("../pages/staticResources/StaticResources")),
    isPrivate: true,
  },
  {
    path: "/project/:uuid/javascript",
    element: lazy(
      () => import("../pages/javascriptProfiler/JavaScriptProfiler")
    ),
    isPrivate: true,
  },
  {
    path: "/project/:uuid/resources-profiler",
    element: lazy(() => import("../pages/resourcesProfiler/ResourcesProfiler")),
    isPrivate: true,
  },
  {
    path: "/project/:uuid/websitesafety",
    element: lazy(() => import("../pages/websiteSafety/WebsiteSafety")),
    isPrivate: true,
  },
  {
    path: "/project/:uuid/recommendation",
    element: lazy(() => import("../pages/recommendation/RecommendationPage")),
    isPrivate: true,
  },
  {
    path: "/project/:uuid/events-monitor",
    element: lazy(() => import("../pages/eventsMonitor/EventsMonitor")),
    isPrivate: true,
  },

  {
    path: "/project/:uuid/webcrawler",
    element: lazy(() => import("../pages/webCrawler/WebCrawler")),
    isPrivate: true,
  },
  {
    path: "/project/:uuid/playground",
    element: lazy(() => import("../pages/playground/Playground")),
    isPrivate: true,
  },
  {
    path: "/project/:uuid/test-schedule",
    element: lazy(() => import("../pages/testSchedule/TestSchedule")),
    isPrivate: true,
  },
  {
    path: "/login",
    element: lazy(() => import("../pages/authentication/Login")),
    isPublic: true,
  },
  {
    path: "/signup",
    element: lazy(() => import("../pages/authentication/Register")),
    isPublic: true,
  },
  {
    path: "/forgot-password",
    element: lazy(() => import("../pages/authentication/ForgotPassword")),
    isPublic: true,
  },
  {
    path: "/forgot-password/:token",
    element: lazy(() => import("../pages/authentication/NewPassword")),
    isPublic: true,
  },
];
